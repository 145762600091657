import * as React from 'react'

export const userSessionLocalStorageKey = '@space__session'

export const AuthContext = React.createContext({
  // User Session Structure:
  // {
  //   user: undefined,
  //   expires: 0,
  // }
  session: undefined,
  setCurrentUser: () => {
    if (typeof window !== 'undefined') {
      window.alert('Session set method needs an override')
    }
    return Promise.resolve()
  }
})
export const useAuth = () => React.useContext(AuthContext)
