import React from 'react'

// custom components
import Header from './Header'
import Footer from './Footer'
import '../styles/main.scss'
import Session from '../hocs/Session'

const Layout = ({ children }) => {
  return (
    <Session>
      <Header />
      <main className="animate__animated animate__fadeIn main_body_content">
        {children}
      </main>
      <Footer />
    </Session>
  )
}

export default Layout
