import * as R from 'ramda'
import React from 'react'
import { graphql, navigate, useStaticQuery } from 'gatsby'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import {
  AppBar,
  Collapse,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemText,
  ListSubheader,
  TextField,
  Toolbar
} from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons/'
import Autocomplete from '@material-ui/lab/Autocomplete'

import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'
import Headroom from 'react-headroom'
import CloseIcon from '@material-ui/icons/Close'
import AniLink from 'gatsby-plugin-transition-link/AniLink'
import sanityClient from '../lib/client.js'

export default () => {
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [menuData, setMenuData] = React.useState([])
  const [menuOpen, setMenuOpen] = React.useState(false)
  const data = useStaticQuery(graphql`
    {
      siteSettings: sanitySiteSettings {
        logo {
          asset {
            fluid(maxHeight: 700) {
              ...GatsbySanityImageFluid
            }
          }
        }
        page_transition_logo {
          asset {
            url
          }
        }
        side_menu_file {
          asset {
            url
          }
        }
        side_menu_file_name
      }
      challenges: allSanityVendorType {
        edges {
          node {
            title
            slug {
              current
            }
            challenge_hero_overlay_image {
              asset {
                fluid {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
      exhibitors: allSanityExhibitors {
        edges {
          node {
            title
            company_primary_contact
            slug {
              current
            }
          }
        }
      }
    }
  `)
  const queryMenu = `*[_type == "main_menu"] {
    menu_items[] {
      title,
      slug,
      btn_url,
      btn_external,
      btn_target
    }
  }`
  const settings = data.siteSettings
  const { edges: challenges } = data.challenges
  const { edges: exhibitors } = data.exhibitors

  React.useEffect(() => {
    sanityClient
      .fetch(queryMenu)
      .then(res => setMenuData(res[0]))
      .catch(err => console.error)
  }, [])

  const toggleDrawer = () => {
    setDrawerOpen(!drawerOpen)
  }

  const handleMenuClick = () => {
    setMenuOpen(!menuOpen)
  }

  const onAutocompleteChange = (event, value) => {
    navigate(`/exhibitor/${value.node.slug.current}`)
  }

  return (
    <Headroom>
      <header className="header">
        <AppBar position="static" className="appbar" elevation={0}>
          <Toolbar>
            {typeof window !== 'undefined' &&
            window.location.pathname !== '/exhibitors' ? (
              <AniLink
                className="logo"
                cover
                to="/"
                duration={1.25}
                direction="right"
                bg={`
                url(${settings.page_transition_logo.asset.url})
                center / 50%     /* position / size */
                no-repeat        /* repeat */
                fixed            /* attachment */
                padding-box      /* origin */
                content-box      /* clip */
                #00021a          /* color */
              `}
              >
                <img
                  src={settings.logo.asset.fluid.src}
                  alt="Afwerx Challenge Logo"
                />
              </AniLink>
            ) : (
              <div className="logo" />
            )}
            <div>
              <IconButton
                onClick={toggleDrawer}
                edge="start"
                color="inherit"
                aria-label="menu"
              >
                <FontAwesomeIcon icon={faBars} className="fa-bars-icon" />
              </IconButton>
            </div>
            <Drawer
              className="main-menu"
              open={drawerOpen}
              anchor="right"
              onClose={toggleDrawer}
              classes={{ paper: 'drawer' }}
            >
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                subheader={
                  <ListSubheader
                    component="div"
                    id="nested-list-subheader"
                    style={{
                      color: '#f4df21',
                      textAlign: 'right '
                    }}
                  >
                    <IconButton
                      onClick={toggleDrawer}
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                    >
                      <CloseIcon />
                    </IconButton>
                  </ListSubheader>
                }
              >
                <Divider />
                <ListSubheader
                  component="div"
                  id="nested-list-subheader"
                  style={{
                    color: '#f4df21',
                    fontFamily: 'Montserrat',
                    fontWeight: 600
                  }}
                >
                  Menu
                </ListSubheader>
                {notNilOrEmpty(menuData) &&
                  mapIndexed((item, index) => {
                    return item.btn_external ? (
                      <a
                        href={item.btn_url}
                        target={item.btn_target ? '_blank' : '_self'}
                        rel="noopener noreferrer"
                      >
                        <ListItem
                          button
                          onClick={toggleDrawer}
                          style={{ color: 'white' }}
                        >
                          <ListItemText
                            primary={item.title}
                            className="parent-list-item"
                          />
                        </ListItem>
                      </a>
                    ) : (
                      <AniLink
                        cover
                        to={item.slug ? `/${item.slug}` : '/'}
                        duration={1.25}
                        direction="right"
                        bg={`
                      url(${settings.logo.asset.fluid.src})
                      center / 50% /* position / size */
                      no-repeat        /* repeat */
                      fixed            /* attachment */
                      padding-box      /* origin */
                      content-box      /* clip */
                      #00021a          /* color */
                    `}
                      >
                        <ListItem
                          button
                          onClick={toggleDrawer}
                          style={{ color: 'white' }}
                        >
                          <ListItemText
                            primary={item.title}
                            className="parent-list-item"
                          />
                        </ListItem>
                      </AniLink>
                    )
                  })(menuData.menu_items)}
                {notNilOrEmpty(settings.side_menu_file) && (
                  <a
                    href={settings.side_menu_file.asset.url}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <ListItem button style={{ color: 'white' }}>
                      <ListItemText
                        primary={`${settings.side_menu_file_name}`}
                        className="parent-list-item"
                      />
                    </ListItem>
                  </a>
                )}
              </List>
            </Drawer>
          </Toolbar>
        </AppBar>
      </header>
    </Headroom>
  )
}
