import * as React from 'react'
import { AuthContext, userSessionLocalStorageKey } from '../context/auth'

export default ({ children }) => {
  const [session, setCurrentUser] = React.useState()

  const setNextuser = async (nextUser, userSession) => {
    try {
      if (nextUser != null && userSession != null) {
        const nextSession = Object.assign({}, { user: nextUser, userSession })
        setCurrentUser(nextSession)
        if (typeof localStorage !== 'undefined') {
          localStorage.setItem(
            userSessionLocalStorageKey,
            JSON.stringify(nextSession)
          )
        }
        return
      } else {
        if (typeof localStorage !== 'undefined') {
          localStorage.removeItem(userSessionLocalStorageKey)
        }
        setCurrentUser(undefined)
        return
      }
    } catch (error) {
      if (typeof window !== 'undefined') {
        window.alert('Failed to persist or update the current user session!')
      }
      return Promise.reject(error)
    }
  }

  const cachedSession =
    typeof localStorage !== 'undefined' &&
    localStorage.getItem(userSessionLocalStorageKey)
  if (cachedSession != null && !session) {
    try {
      const { user, userSession } = JSON.parse(cachedSession)
      setNextuser(user, userSession)
    } catch (error) {
      console.error(error)
    }
  }

  const providerState = {
    setCurrentUser: setNextuser,
    session
  }
  const { Provider } = AuthContext
  return <Provider value={providerState}>{children}</Provider>
}
